

























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { debounce } from "lodash";

import CustomAutocomplete from "@/shared/components/pickers/CustomAutocomplete.vue";
import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import CounterpartyNetworkRequestModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkRequestModel";
import CounterpartyNetworkResponseModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import { Dictionary, DictionaryType } from "@/shared/models";

@Component({
  components: {
    CustomAutocomplete,
    PickerDictionaryNotification,
  },
})
export default class NetworkForm extends Vue {
  @Prop() value!: CounterpartyNetworkRequestModel;
  @Prop() editingItem!: CounterpartyNetworkResponseModel;

  readonly REQUIRED_RULE = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  menuDateFrom = false;
  menuDateTo = false;
  search: string | null = null;
  currentSearch: string | null = null;

  get localValue(): CounterpartyNetworkRequestModel {
    return this.value;
  }

  set localValue(value: CounterpartyNetworkRequestModel) {
    this.$emit("input", value);
  }

  get minDateTo(): string {
    return this.localValue.dateFrom;
  }

  get isFullResult(): boolean {
    return this.$store.state.dictionaryStore[
      DictionaryType.ALL_AD_NETWORK_NAMES
    ].isFullResult;
  }

  get items(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[
      DictionaryType.ALL_AD_NETWORK_NAMES
    ].values;
  }

  get applicationIds(): string {
    return this.$store.getters.applicationIds;
  }

  @Watch("search")
  watchSearch = debounce(this.initSearch, 500);

  onSearchChange(value: string | null) {
    this.search = value;
  }

  initSearch(search: string | null) {
    if (
      (this.isFullResult && !this.currentSearch) ||
      this.currentSearch === search ||
      (!this.currentSearch && (!search || search.length < 2))
    ) {
      return;
    }

    this.currentSearch = search && search.length > 1 ? search : null;
    this.$store.dispatch("loadDictionary", {
      app: this.applicationIds,
      type: DictionaryType.ALL_AD_NETWORK_NAMES,
      search: this.currentSearch,
    });
  }
}
