























import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import EntityCard from "@/accounting-portal/components/counterparties/EntityCard.vue";
import NetworkForm from "@/accounting-portal/components/counterparties/networks/NetworkForm.vue";
import NetworksTable from "@/accounting-portal/components/counterparties/networks/NetworksTable.vue";
import CounterpartyNetworkRequestModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkRequestModel";
import CounterpartyNetworkResponseModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";
import { AppSection, DictionaryType } from "@/shared/models";

@Component({
  components: {
    EntityCard,
    NetworksTable,
    NetworkForm,
  },
})
export default class NetworksView extends mixins(AppSectionAccessMixin) {
  visible = false;
  localValue = new CounterpartyNetworkRequestModel();
  editingItem: CounterpartyNetworkResponseModel | null = null;

  get saving(): boolean {
    return this.$store.state.counterpartyStore.isNetworkSaving;
  }

  get networks(): Array<CounterpartyNetworkResponseModel> {
    return this.$store.state.counterpartyStore.networks;
  }

  get counterpartyId(): number {
    return Number(this.$route.params.counterpartyId);
  }

  get formTitle(): string {
    return this.editingItem
      ? this.$lang("accountingPortal.counterparties.networks.editTitle")
      : this.$lang("accountingPortal.counterparties.networks.newTitle");
  }

  get applicationIds(): string {
    return this.$store.getters.applicationIds;
  }

  get appSection(): AppSection {
    return AppSection.COUNTERPARTIES;
  }

  created() {
    Promise.all([
      this.$store.dispatch("loadCounterpartyNetworks", this.counterpartyId),
      this.$store.dispatch("loadDictionaries", {
        app: this.applicationIds,
        dictionaryTypes: [DictionaryType.ALL_AD_NETWORK_NAMES],
      }),
    ]);
  }

  beforeDestroy() {
    this.$store.commit("clearCounterpartyNetworks");
  }

  handleChangeVisible(value: boolean) {
    this.visible = value;

    if (!value) {
      this.localValue = new CounterpartyNetworkRequestModel();
      this.editingItem = null;
    }
  }

  handleClickByTableRow(item: CounterpartyNetworkResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.editingItem = CounterpartyNetworkResponseModel.of(item);
    this.localValue = new CounterpartyNetworkRequestModel(
      this.counterpartyId,
      item.name,
      item.dateFrom,
      item.dateTo
    );

    this.visible = true;
  }

  async handleSave() {
    if (this.editingItem) {
      await this.$store.dispatch("updateCounterpartyNetwork", {
        id: this.editingItem.id,
        payload: this.localValue,
      });
    } else {
      this.localValue.counterpartyId = this.counterpartyId;
      await this.$store.dispatch("createCounterpartyNetwork", this.localValue);
    }

    this.handleChangeVisible(false);
  }
}
